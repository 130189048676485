import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";

export const frontmatter = {
  title: "Día 08",
  week: "Semana 2",
  month: "Mar",
  day: "21",
  monthNumber: "03",
  date: "2020-03-21",
  path: "/cronologia/semana-02#dia-21-mar",
};

const Day08 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 4.946 nuevos positivos y 324 personas fallecidas.
          Permanecen hospitalizadas 13.282 personas, 6.577 más que el día
          anterior, y 585 reciben el alta. El número de casos ingresados en la
          UCI asciende a 1.612, lo que supone un aumento de 487 personas.
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 5,3 % y la tasa de recuperados del 8,7 %.
        </ModText>
        <ModTwoCols
          src="/images/svg/21_mar_comunidad_madrid.svg"
          alt="Atributo alt"
          small={false}
        >
          La <strong>Comunidad de Madrid</strong> prepara en el recinto ferial
          de IFEMA, con el apoyo de la Unidad Militar de Emergencias (UME), un
          hospital con 5.500 camas de hospitalización y UCI para los enfermos de
          coronavirus más graves. Asimismo, ha contratado hasta el momento 4.900
          profesionales para reforzar la atención sanitaria.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/21_mar_fuerzas_armadas.svg"
          alt="Atributo alt"
          small={false}
        >
          Las <strong>Fuerzas Armadas</strong> mantienen desplegados 2.640
          efectivos en 55 ciudades para apoyar diferentes servicios sociales, la
          instalación de infraestructuras temporales, y llevar a cabo tareas de
          reconocimiento de necesidades, de presencia y de desinfección.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/21_mar_carnet_conducir.svg"
          alt="Atributo alt"
          small={false}
        >
          El <strong>Ministerio del Interior</strong> prórroga durante 60 días
          la vigencia de los carnés de conducir que caduquen durante el estado
          de alarma.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/21_mar_muface.svg"
          alt="Atributo alt"
          small={false}
        >
          Los mutualistas que estaban obligados a sellar las recetas en las
          oficinas de <strong>MUFACE</strong> para la obtención de sus
          medicamentos, por precisar autorización, podrán adquirirlos con receta
          directamente en la farmacia sin necesidad de hacer ese desplazamiento.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/21_mar_agricultura.svg"
          alt="Atributo alt"
          small={false}
        >
          <strong>Agricultura, Pesca y Alimentación</strong> prorroga el plazo,
          hasta el 15 de mayo, de presentación de la solicitud única de los
          pagos directos a la agricultura y ganadería para el año 2020, con el
          objetivo de que agricultores y ganaderos puedan presentar su solicitud
          sin problemas. El Fondo Español de Garantía Agraria (FEGA), junto a
          las autonomías, impulsa los procesos de presentación telemática.
        </ModTwoCols>
        <ModImage
          src="/images/svg/21_mar_comite_cientifico.svg"
          alt="comité científico covid-19"
        />
        <ModText>
          Hoy se ha constituido el Comité Científico del COVID-19 integrado por
          seis técnicos de prestigio nacional e internacional a cuyo frente se
          encuentra el director del Centro de Coordinación de Alertas y
          Emergencias Sanitarias (CCAES).
        </ModText>
        <ModReferenceList title="Guías y Docuemntos Publicados">
          <ReferenceRow
            link="https://www.mscbs.gob.es/gabinete/notasPrensa.do?metodo=detalle&id=4822"
            name="Comité científico del Covid-19"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day08;
