import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";

import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

export const frontmatter = {
  title: "Día 10",
  week: "Semana 2",
  month: "Mar",
  day: "23",
  monthNumber: "03",
  date: "2020-03-23",
  path: "/cronologia/semana-02#dia-24-mar",
};

const Day10 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 4.517 nuevos positivos y 462 personas fallecidas.
          Permanecen hospitalizadas 18.401 personas, 2.847 más que el día
          anterior, y 441 reciben el alta. El número de casos ingresados en la
          UCI asciende a 2.355, lo que supone un aumento de 570 personas.
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 6,6 % y la tasa de recuperados del 9,4 %.
        </ModText>
        <ModTwoCols
          src="/images/svg/23_mar_presidente.svg"
          alt="Propuesta ampliación estado de alarma"
          small={true}
        >
          El Presidente del Gobierno propuso ayer adoptar la{" "}
          <strong>
            ampliación del estado de alarma 15 días, hasta el próximo día 11 de
            abril
          </strong>
          , que deberá ser aprobado por el Congreso de los Diputados.
        </ModTwoCols>
        <ModText>
          Las <strong>Fuerzas Armadas</strong> han desplegado a 2.850 efectivos
          en 129 localidades para llevar a cabo trabajos de mantenimiento,
          despliegue de campamentos, apoyo sanitario, traslado de pacientes y
          desinfección en residencias de mayores.
        </ModText>
        <ModImage
          src="/images/svg/23_mar_aeropuerto.svg"
          alt="Vuelos especiales para repatriar a compatriotas"
        />
        <ModText>
          Gracias a la intensa labor de los 4.500 profesionales que están
          desplegados por todo el mundo, en los últimos días han salido{" "}
          <strong>
            vuelos especiales coordinados con las Embajadas y Consulados
            Generales de España
          </strong>{" "}
          con el objetivo de traer de vuelta a España a ciudadanos en el
          extranjero.
        </ModText>
        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/agricultura/Paginas/2020/230320-covid19_pesca.aspx"
            name="Indicaciones del Ministerio de agricultura, Pesca y Alimentación"
          />
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/agricultura/Paginas/2020/230320-covid-info.aspx"
            name="Recomendaciones Ministerio de Agricultura, Pesca y Alimentación"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day10;
