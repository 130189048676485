import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";

import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

export const frontmatter = {
  title: "Día 09",
  week: "Semana 2",
  month: "Mar",
  day: "22",
  monthNumber: "03",
  date: "2020-03-22",
  path: "/cronologia/semana-02#dia-22-mar",
};

const Day09 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 3.646 nuevos positivos y 394 personas fallecidas.
          Permanecen hospitalizadas 15.554 personas, 2.272 más que el día
          anterior, y 505 reciben el alta. El número de casos ingresados en la
          UCI asciende a 1.785, lo que supone un aumento de 173 personas.
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 6,0 % y la tasa de recuperados del 9,4 %.
        </ModText>
        <ModTwoCols
          src="/images/svg/22_mar_comunidad_madrid.svg"
          alt="MAdrid habilita las 100 primeras camas en IFEMA"
          small={false}
        >
          La <strong>Comunidad de Madrid</strong> ha habilitado las primeras
          1.396 camas hospitalarias en IFEMA (1.300 camas convencionales y 96
          puestos de UCI). Además, contará con hasta 200 camas provisionales
          para recibir de inmediato a pacientes con sintomatología leve
          derivados de distintos hospitales de la región.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/22_mar_unididad_militar.svg"
          alt="flota de autobuses para la UME"
          small={false}
        >
          Asimismo, se ha puesto a disposición de la{" "}
          <strong>Unidad Militar de Emergencias</strong> (UME) la flota de
          autobuses urbanos e interurbanos que opera en la región para el
          desplazamiento de enfermos entre los distintos centros hospitalarios
          de la región.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/22_mar_mascarillas_sanitarios.svg"
          alt="mascarillas para pacientes y profesionales sanitarios"
          small={false}
        >
          El El Ministerio de Sanidad ha repartido este fin de semana 1.602.800
          de mascarillas entre las comunidades autónomas. En total, desde el 10
          de marzo se han distribuido más de 4 millones de mascarillas a
          pacientes y profesionales sanitarios.
        </ModTwoCols>
        <ModImage
          src="/images/svg/22_mar_puerto_fronteras.svg"
          alt="cierre de fronteras"
        />
        <ModText>
          El Gobierno ha acordado{" "}
          <strong>
            restringir el acceso de viajeros a través de las fronteras
            exteriores de España
          </strong>
          , en concreto las situadas en puertos y aeropuertos: sólo se permitirá
          el acceso por esos puntos fronterizos a los ciudadanos españoles y a
          los residentes en España, y a colectivos muy determinados.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day09;
