import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";

import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

export const frontmatter = {
  title: "Día 13",
  week: "Semana 2",
  month: "Mar",
  day: "26",
  monthNumber: "03",
  date: "2020-03-26",
  path: "/cronologia/semana-02#dia-27-mar",
};

const Day19 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <hr className="eu" />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 8.578 nuevos positivos y 655 personas fallecidas.
          Permanecen hospitalizadas 31.912 personas, 4.952 más que el día
          anterior, y 1.272 reciben el alta. El número de casos ingresados en la
          UCI asciende a 3.679, lo que supone un aumento de 513 personas.
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 7,3 % y la tasa de recuperados del 11,3 %.
        </ModText>
        <ModTwoCols
          src="/images/svg/26_mar_mascarilla.svg"
          alt="mascarillas"
          small={true}
        >
          Desde el pasado 10 de marzo,{" "}
          <strong>se han distribuido en total 6.748.268 mascarillas</strong>.
          Las comunidades que mayor número de mascarillas han recibido hasta el
          momento son Madrid y Cataluña, con 1.922.068 Y 887.073
          respectivamente, por ser las zonas que presentan un mayor número de
          contagios.
        </ModTwoCols>
        <ModText>
          Además, se ha impulsado la contratación inmediata de cerca de 200
          profesionales sanitarios de terceros países, y actualmente residiendo
          en España, para que se incorporen al SNS y ofrecer una respuesta
          coordinada y efectiva a la crisis sanitaria del COVID-19.
        </ModText>
        <ModTwoCols
          src="/images/svg/26_mar_instituto_nacional.svg"
          alt="formación en remoto"
          small={true}
        >
          El{" "}
          <strong>Instituto Nacional de Administración Pública (INAP)</strong>{" "}
          mantiene toda su programación de actividades de formación para las
          empleadas y los empleados públicos, y se impartirá por vía
          electrónica, mientras dure el estado de alarma.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/26_mar_museo.svg"
          alt="museo "
          small={true}
          inverted={true}
        >
          Los <strong>museos</strong> dependientes del Ministerio de Cultura y
          Deporte, junto al Museo Nacional del Prado, Museo Nacional Centro de
          Arte Reina Sofía y Museo Nacional Thyssen-Bornemisza{" "}
          <strong>
            han preparado diferentes recursos digitales para familias y niños
          </strong>{" "}
          que posibilitan el entretenimiento a la vez que el aprendizaje, en el
          marco de la campaña #LaCulturaEnTuCasa.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/26_mar_estacion_servicio.svg"
          alt="area de servicio"
          small={false}
        >
          El Gobierno declarará como{" "}
          <strong>
            servicios esenciales determinadas estaciones y áreas de servicio{" "}
          </strong>
          con el fin de prestar servicios de restauración a transportistas
          profesionales de mercancías y de viajeros, Fuerzas y Cuerpos de
          Seguridad del Estado y otros trabajadores en similares circunstancias.
        </ModTwoCols>
        <ModText>
          El personal sanitario también podrá acceder de forma gratuita a los
          trenes de Alta Velocidad, de Larga Distancia y de Media Distancia y
          Avant.
        </ModText>
        <ModTwoCols src="/images/svg/26_mar_taxi.svg" alt="taxi" small={false}>
          En referencia al <strong>sector del taxi</strong>, podrán beneficiarse
          del aplazamiento de sus deudas tributarias y tienen derecho a la
          prestación por reducción de la actividad (acreditando una reducción de
          al menos del 75%, aunque tributen por el sistema de estimación
          objetivo).
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/26_mar_servicio_publico.svg"
          alt="test coronavirus"
          small={false}
        >
          El <strong>Servicio Público de Empleo Estatal (SEPE)</strong> toma
          medidas desde el comienzo de la alerta sanitaria para hacer frente a
          la situación generada por la evolución del COVID- 19. Entre ellas, se
          contemplan medidas de protección por desempleo para los trabajadores
          afectados por suspensiones de contrato y reducciones de jornada
          (ERTEs).
        </ModTwoCols>
        <ModText>
          El <strong>Ministerio de Igualdad</strong> lanza una campaña de
          información a las víctimas de violencia de género para dar a conocer
          los servicios disponibles a su alcance. Asimismo, en coordinación con
          las Comunidades Autónomas, ha actualizado los recursos, tanto
          presenciales, como no presenciales, contra la violencia de género
          disponibles en la situación actual.
        </ModText>
        <ModImage
          src="/images/svg/26_mar_telefonos.svg"
          alt="telefonos de asesoramiento contra la violencia de género"
        />
        <ModText>
          El <strong>Colegio de Registradores de España</strong> ha habilitado
          la vía telemática para agilizar la tramitación de las notas de índice
          de propiedades, un documento necesario para solicitar al banco el
          aplazamiento del pago del préstamo hipotecario de la vivienda habitual
          si se acredita vulnerabilidad económica.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};

export default Day19;
