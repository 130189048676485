import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";

import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import { StrongGold } from "../../../components/Textstyles/Styles";

export const frontmatter = {
  title: "Día 12",
  week: "Semana 2",
  month: "Mar",
  day: "25",
  monthNumber: "03",
  date: "2020-03-25",
  path: "/cronologia/semana-02#dia-26-mar",
};

const Day12 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
        <ModCovidHighlight>
          España supera a China en número de fallecidos
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 7.937 nuevos positivos y 738 personas fallecidas.
          Permanecen hospitalizadas 26.960 personas, 4.148 más que el día
          anterior, y 1.357 reciben el alta. El número de casos ingresados en la
          UCI asciende a 3.166, lo que supone un aumento de 530 personas.
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 7,2 % y la tasa de recuperados del 10,6 %.
        </ModText>
        <ModDailyHighlight>
          <strong>El número de personas fallecidas por COVID-19</strong> en la
          actualidad ya es{" "}
          <StrongGold>
            superior al número de muertes notificadas en China
          </StrongGold>
          .
        </ModDailyHighlight>
        <ModText>
          El <strong>Gobierno de España</strong> se ha sumado a la declaración
          suscrita por 14 países (Australia, Brasil, Canadá, Francia, Alemania,
          India, Italia, Japón, Nueva Zelanda, Corea del Sur, Portugal, Singapur
          y Reino Unido) para impulsar el acceso a toda la información publicada
          sobre la pandemia de coronavirus.
        </ModText>
        <ModTwoCols
          src="/images/svg/25_mar_ministerio_edu.svg"
          alt="cambio en el calendario"
          small={true}
        >
          Los <strong>ministerios de Educación y FP y Universidades</strong>{" "}
          acuerdan cambios en el calendario y en las pruebas de acceso a la
          Universidad (EBAU). La prueba se celebrará entre el 22 de junio y el
          10 de julio en convocatoria ordinaria y antes del 10 de septiembre en
          convocatoria extraordinaria.
        </ModTwoCols>
        <ModText>
          También se han establecido una serie de medidas para que los{" "}
          <strong>estudiantes de FP</strong> superen el curso, y entre ellas
          están la flexibilidad en las prácticas y el calendario escolar.
        </ModText>
        <ModText>
          En cuanto a los alumnos que están realizando este curso en el
          extranjero, el MEFP establecerá sistemas específicos para homologar
          sus estudios, dado que la mayoría del alumnado está continuando las
          clases en la modalidad online.
        </ModText>
        <ModTwoCols
          src="/images/svg/25_mar_alojamiento_turistico.svg"
          alt="alojamientos turísticos para los trabajadores de soporte frente al covid-19"
          small={false}
          inverted={true}
        >
          El Ministerio de Transportes, Movilidad y Agenda Urbana declara como
          servicio esencial 370 alojamientos turísticos de todo el país para
          atender a trabajadores de sectores específicos: aquellos que realicen
          labores de mantenimiento, asistencia sanitaria, reparación y obras,
          suministro y transporte de servicios esenciales.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/25_mar_pymes_deporte.svg"
          alt="avales para el sector deportivo"
          small={true}
        >
          Pymes y autónomos del sector cultural y deportivo se pueden acoger a
          la línea de avales aprobada por el Gobierno y será gestionada a través
          del Instituto de Crédito Oficial (ICO).
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/25_mar_comite_olimpico.svg"
          alt="comité olímpico español logo"
          small={true}
          inverted={true}
        >
          El Comité Olímpico Internacional (COI) ha decidido aplazar los
          próximos Juegos Olímpicos y Paralímpicos de Tokio 2020 a 2021 tras
          llegar a un acuerdo con el primer ministro japonés Shinzo Abe, por la
          situación creada por la pandemia del coronavirus.
        </ModTwoCols>
        <ModText>
          Con la finalidad de frenar la expansión del virus, el Ministerio de
          Sanidad ha hecho una compra de material por 432 millones de euros con
          China, que incluye mascarillas quirúrgicas y de protección
          respiratoria, test rápidos y guantes, entre otros.
        </ModText>

        <ModTwoCols
          src="/images/svg/25_mar_otan.svg"
          alt="bandera de la otan"
          small={true}
        >
          Asimismo, España también ha solicitado asistencia internacional a
          través de la OTAN por la falta de material en el sistema sanitario.
        </ModTwoCols>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day12;
