import React from "react";
import LayoutWeek from "../../../components/Chronology/LayoutWeek";
import Day08 from "./dia-21-mar";
import Day09 from "./dia-22-mar";
import Day10 from "./dia-23-mar";
import Day11 from "./dia-24-mar";
import Day12 from "./dia-25-mar";
import Day13 from "./dia-26-mar";
import Day14 from "./dia-27-mar";
import SecWeekly, {
  SecWeeklyColumn,
} from "../../../components/Chronology/Modules/SecWeekly";
import ModMegaBanner from "../../../components/Chronology/Modules/ModMegaBanner";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import { StrongGold } from "../../../components/Textstyles/Styles";

const Week = (props) => {
  return (
    <>
      <LayoutWeek {...props}>
        <SecWeekly
          period="21 Marzo - 27 Marzo"
          week="Semana 2 de confinamiento"
        >
          <SecWeeklyColumn>
            Se <strong>prórroga el estado de alarma</strong>
            hasta el próximo 11 de abril
            <ModDottedLine />
            <strong>Aprendo en casa,</strong> portal con recursos, herramientas
            y aplicaciones educativas
            <ModDottedLine />
            COVID-19 confina a<strong>un tercio de la humanidad</strong>
            <ModDottedLine />
            Se aplazan los
            <strong>Juegos Olímpicos y Paralímpicos de Tokio 2020</strong>
            <ModDottedLine />
          </SecWeeklyColumn>
          <SecWeeklyColumn>
            <strong>Coronamadrid,</strong>la ‘App’ para la autoevaluación de los
            ciudadanos
            <ModDottedLine />
            <strong>Permiso retribuido recuperable</strong> para las personas
            trabajadoras de servicios no esenciales
            <ModDottedLine />
            <strong>Ministerio de Educación, FP y Universidades</strong>{" "}
            acuerdan cambios en el calendario y pruebas de acceso
          </SecWeeklyColumn>
        </SecWeekly>
        <Day14 {...props} />
        <Day13 {...props} />
        <ModMegaBanner>
          El <strong>Comité Olímpico Internacional aplaza</strong> los{" "}
          <StrongGold>Juegos Olímpicos</StrongGold> y Paraolímpicos de Tokio
          2020
        </ModMegaBanner>
        <Day12 {...props} />
        <Day11 {...props} />
        <Day10 {...props} />
        <Day09 {...props} />
        <Day08 {...props} />
      </LayoutWeek>
    </>
  );
};

export default Week;
